:root {
    --image-size: 200px;
    
    @media screen and (min-width: $tablet-breakpoint) {
        --image-size: 400px;
    }
    
    @media screen and (min-width: $desktop-breakpoint) {
        --image-size: 400px;
    }
}

img {
    object-fit: cover;
    width: var(--image-size);
    height: var(--image-size);

}