// MARGIN BOTTOM
.mb-{
    &5 {
        margin-bottom: 5px;
    }
    &10 {
        margin-bottom: 10px;
    }
    &15 {
        margin-bottom: 15px;
    }
    &20 {
        margin-bottom: 20px;
    }
    &30 {
        margin-bottom: 30px;
    }
    &40 {
        margin-bottom: 40px;
    }
    &60 {
        margin-bottom: 60px;
    }
    &80 {
        margin-bottom: 80px;
    }
    &100 {
        margin-bottom: 100px;
    }
}

.mt-{
    &5 {
        margin-top: 5px;
    }
    &10 {
        margin-top: 10px;
    }
    &15 {
        margin-top: 15px;
    }
    &20 {
        margin-top: 20px;
    }
    &30 {
        margin-top: 30px;
    }
    &40 {
        margin-top: 40px;
    }
    &60 {
        margin-top: 60px;
    }
    &80 {
        margin-top: 80px;
    }
    &100 {
        margin-top: 100px;
    }
}

.mx-{
    &10 {
        margin-left: 10px;
        margin-right: 10px;
    }
    &15 {
        margin-left: 15px;
        margin-right: 15px;
    }
    &20 {
        margin-left: 20px;
        margin-right: 20px;
    }
    &30 {
        margin-left: 30px;
        margin-right: 30px;
    }
    &40 {
        margin-left: 40px;
        margin-right: 40px;
    }
    &60 {
        margin-left: 60px;
        margin-right: 60px;
    }
}

.mr-{
    &5 {
        margin-right: 5px;
    }
    &10 {
        margin-right: 10px;
    }
    &15 {
        margin-right: 15px;
    }
    &20 {
        margin-right: 20px;
    }
    &30 {
        margin-right: 30px;
    }
    &40 {
        margin-right: 40px;
    }
    &60 {
        margin-right: 60px;
    }
}

.ml-{
    &5 {
        margin-left: 5px;
    }
    &10 {
        margin-left: 10px;
    }
    &15 {
        margin-left: 15px;
    }
    &20 {
        margin-left: 20px;
    }
    &30 {
        margin-left: 30px;
    }
    &40 {
        margin-left: 40px;
    }
    &60 {
        margin-left: 60px;
    }
}