// project
@import "./variables.scss";
@import "./text.scss";
@import "./container.scss";
@import "./image.scss";
@import "./home.scss";

// util styling
@import "./reset.scss";
@import "./margin.scss";
@import "./display.scss";

.App {
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}